<template>
  <Auth>
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card">
          <div class="card-body p-4">
            <div class="text-center w-75 m-auto">
              <div class="auth-logo">
                <router-link to="/" class="logo logo-dark text-center">
                  <span class="logo-lg">
                    <img
                      src="@/assets/images/Circular-Techno-G-Logo-04-04.jpg"
                      alt=""
                      height="250"
                    />
                  </span>
                </router-link>

                <router-link to="/" class="logo logo-light text-center">
                  <span class="logo-lg">
                    <img
                      src="@/assets/images/Circular-Techno-G-Logo-04-04.jpg"
                      alt=""
                      height="22"
                    />
                  </span>
                </router-link>
              </div>
              <p class="text-muted mb-4 mt-3">
                Enter your email address and password to gain access.
              </p>
            </div>

            <form action="#" @submit.prevent="tryToLogIn">
              <b-alert
                :variant="notification.type"
                class="mt-3"
                v-if="notification.message"
                :show="notificationAutoCloseDuration"
                dismissible
                >{{ notification.message }}</b-alert
              >
              <b-alert
                variant="danger"
                class="mt-3"
                v-model="isAuthError"
                :show="notificationAutoCloseDuration"
                dismissible
                >{{ authError }}</b-alert
              >
              <div class="form-group mb-3">
                <label for="emailaddress">Email address</label>
                <input
                  class="form-control"
                  v-model="email"
                  type="email"
                  id="emailaddress"
                  placeholder="Enter your email"
                  :class="{ 'is-invalid': submitted && $v.email.$error }"
                />
                <div
                  v-if="submitted && $v.email.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.email.required">Email is required.</span>
                  <span v-if="!$v.email.email">Please enter valid email.</span>
                </div>
              </div>

              <div class="form-group mb-3">
                <label for="password">Password</label>
                <div class="input-group input-group-merge">
                  <input
                    v-model="password"
                    type="password"
                    id="password"
                    class="form-control"
                    placeholder="Enter your password"
                    :class="{ 'is-invalid': submitted && $v.password.$error }"
                  />

                  <div class="input-group-append" data-password="false">
                    <div class="input-group-text">
                      <span class="password-eye"></span>
                    </div>
                  </div>
                  <div
                    v-if="submitted && !$v.password.required"
                    class="invalid-feedback"
                  >
                    Password is required.
                  </div>
                </div>
              </div>

              <div class="form-group mb-0 text-center">
                <button class="btn btn-primary btn-block" type="submit">
                  Log In
                </button>
              </div>
            </form>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->

        <div class="row mt-3">
          <div class="col-12 text-center">
            <p>
              <router-link to="/forgot-password" class="text-muted ml-1"
                >Forgot your password?</router-link
              >
            </p>
          </div>
          <!-- end col -->
        </div> 
        <!-- end row -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Auth>
</template>

<script>
import Auth from "../../layouts/auth";
import appConfig from "../../../../app.config";

import { required, email } from "vuelidate/lib/validators";
import { mapActions } from 'vuex'
/**
 * Login component
 */
export default {
  page: {
    title: "Login",
    meta: [{ name: "description", content: appConfig.description }],
  },
  
  components: {
    Auth,
  },

  data() {
    return {
      email: "",
      password: "",
      submitted: false,
      authError: null,
      tryingToLogIn: false,
      isAuthError: false,
      notification: {
        message: "",
        type: ""
      }
    };
  },
  
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
    },
  },

  computed: {
    notificationAutoCloseDuration() {
      return this.$store && this.$store.state.notification ? 5 : 0;
    },
  },

  methods: {
    ...mapActions('authModule', ['login']),

    // Try to log the user in with the username
    // and password they provided.
    async tryToLogIn() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) return;

      const { email, password } = this;
      if (email && password) {
        const { message, error, user } = await this.login({email, password});
        console.log(error)
        if(!error) {
          if(user.role == "ADMIN")
            this.$router.push({ name: "admin-home" });
          else if(user.role == "BRANCH-MANAGER")
            this.$router.push({ name: "manager-home" });
          else if(user.role == "EMPLOYEE")
            this.$router.push({ name: "employee-home" });

        } else {
          this.notification.message = message
          this.notification.type = "danger"
        }
      }
    }
  },
  
};
</script>
